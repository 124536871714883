import baseComponent from '@/scripts/baseComponent';
import authApi from '@/api/common/auth';
import { API_CALL_STATUS } from "@/constant/config";
import { RESTRICTED_SOFTWARE_MODIFY_TYPE } from "@/constant/systemManagerStatus";
import { mapState } from "vuex";

export default {

    extends: baseComponent,

    data() {
        return {
            software: null,
            description: null
        };
    },

    props: {
        element: {
            type: Array,
            default: []
        },
    },

    computed: {
        ...mapState({
            user: state => state.auth.user,
            listServicePack: state => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {
        if (this.element.length === 1) {
            this.software = this.element[0].software;
            this.description = this.element[0].description;
        } else {
            this.software = null;
            this.description = null;
        }
    },

    methods: {

        // Close this pop-up
        closePopup() {
            this.$emit("processingEvent", { event: "closePopup", data: "restricted-software-modify" });
        },


        // Modify software
        modifySoftware() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            let request = {
                listData: this.element,
                type: RESTRICTED_SOFTWARE_MODIFY_TYPE.INFORMATION,
                software: this.software,
                description: this.description,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            authApi.restrictedSoftwareModify(request)
                .then((res) => {
                    try {
                        this.closePopup();
                        this.$emit("processingEvent", { event: "performAction", data: { action: "refresh" } });
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },
    },
}